import {
    faGooglePlay,
    faApple,
    faTwitch,
    faSteam
} from "@fortawesome/free-brands-svg-icons";
import GameCenter from "../images/game-center-128x128.png";

import { useNavigate } from "react-router-dom";
import NKAPI from "./NKAPI";
import { useContext, useState } from "react";
import Context from "../store/context";
import Button from "./Elements/Button";
import { useTranslation } from "react-i18next";

// TODO: Remove facebook/google on facebook apps (no longer valid from webview browsers https://ninjakiwi.slack.com/archives/GHREYTNR3/p1641811589004000)

export default function LoginProviders(props) {
    // Global State
    const { globalDispatch } = useContext(Context);
    const navigate = useNavigate();

    const { t } = useTranslation(); // locale

    const [isProviderInflight, setIsProviderInflight] = useState("");
    function loginProvider(e) {
        let provider = e.target.id;
        if (!provider) return;

        setIsProviderInflight(provider);
        // Check if facebook
        if (provider === "facebook") {
            // If platform is steam, do normal flow, else use Facebook SDK
            if (NKAPI.platform && NKAPI.platform !== "steam") {
                // For support apps, use LiNK code FB login method
                if (facebookLoginURL() !== null) {
                    setIsProviderInflight("");
                    return;
                }
                // Login using SDK
                loginProviderSDK(provider);
            } else {
                // Login using OAuth flow
                loginProviderEndpoint(provider);
            }
        } else if (provider === "twitch") {
            // Login using OAuth flow
            loginProviderEndpoint(provider);
        } else {
            // Login using SDK
            loginProviderSDK(provider);
        }
    }
    function facebookLoginURL() {
        let baseURL = "https://ninja.kiwi/";
        if (NKAPI.isStaging() || NKAPI.isDev()) baseURL += "staging/";

        switch (NKAPI.appID) {
            case 2:
                return baseURL + "battles_facebook";
            case 3:
                return baseURL + "sas4_facebook";
            case 11:
                return baseURL + "btd6_facebook";
            case 16:
                return baseURL + "bat_facebook";
            case 17:
                return baseURL + "battles2_facebook";
            case 19:
                return baseURL + "pop_facebook";
            default:
                return null;
        }
    }
    let providerListener;
    let intervalTimeout = 35;
    let intervalCount = 0;
    function loginProviderEndpoint(provider) {
        // Get provider OAuth links from api
        NKAPI.request(`/user/${provider}/start`, {})
            .then((data) => {
                let loginData = JSON.parse(data.data.data);

                if (NKAPI.debug)
                    window.open(loginData.facebookStartRedirectURL);
                else window.location = loginData.facebookStartRedirectURL;

                // Start listening for OAuth completion
                intervalCount = 0;
                providerListener = setInterval(() => {
                    if (provider === "twitch" || provider === "facebook")
                        intervalTimeout = 60;
                    else intervalTimeout = 35;
                    loginProviderListener(provider, loginData.linkToken);
                }, 2000);
            })
            .catch((e) => {
                if (e?.toJSON()?.message === "Network Error") {
                    // No internet connection, show lost connection screen
                    globalDispatch({
                        type: "modal/offline",
                        payload: {
                            isShown: true
                        }
                    });
                }
                loginProviderComplete();
            });
    }
    function loginProviderSDK(provider) {
        // Listen for errors
        intervalCount = 0;
        providerListener = setInterval(() => {
            loginProviderListener(null, null, true);
        }, 2000);

        // Login using SDK
        NKAPI.sendToSDK(`//onlylogin?provider=${provider}`);
    }
    function loginProviderListener(provider, linkToken, sdk) {
        intervalCount++;
        // Check if provider has errored
        if (NKAPI.hasProviderErrored || intervalCount >= intervalTimeout) {
            NKAPI.hasProviderErrored = false;
            // Cancel spinner
            setIsProviderInflight("");
            clearInterval(providerListener);

            if (NKAPI.errorFromClient === "ERR_SDK_USER_CANNOT_FIND_LINK") {
                NKAPI.errorFromClient = "";
                // Got error from client, show relative message
                globalDispatch({
                    type: "modal/error",
                    payload: {
                        isShown: true,
                        errorMsg: "error.provider_notlinked"
                    }
                });

                return;
            }

            // Else show generic error
            globalDispatch({
                type: "modal/error",
                payload: {
                    isShown: true,
                    errorMsg: "error.login_timedout"
                }
            });
            return;
        }

        // Pop provider fix - manually send /loaded so the game sends up the sessionID
        if (NKAPI.appID === 19 || NKAPI.appID === 11 || NKAPI.appID === 21) {
            if (!NKAPI.isAppLoaded) {
                NKAPI.isAppLoaded = true;
                NKAPI.sendToSDK("//loaded");
            }
            // if (window.location.hostname.includes("staging")) {
            //     fetch(
            //         encodeURI(
            //             `https://noti.ninja.kiwi/rossmarc/basic_message/message|Login provider - BTD6/Pop/`
            //         )
            //     );
            // }

            // Client will also call sessionIsAvailable which will trigger the login on webview
        }

        if (NKAPI.sessionID) {
            // Check if client has provided a session, if so log user in
            NKAPI.request("/user/current", {})
                .then((d) => {
                    // Valid session, log user in
                    globalDispatch({
                        type: "user/login",
                        payload: {
                            user: JSON.parse(d.data.data).user,
                            session: JSON.parse(d.data.data).session
                        }
                    });

                    // Stop listening
                    setIsProviderInflight("");
                    clearInterval(providerListener);

                    // Go to profile page
                    navigate("/profile");
                })
                .catch((e) => {
                    // Show error
                    globalDispatch({
                        type: "modal/error",
                        payload: {
                            isShown: true
                        }
                    });
                });
            return;
        }

        // Check oauth status
        if (!sdk) {
            NKAPI.request(`/user/facebook/update`, {
                linkToken: linkToken
            })
                .then((data) => {
                    let providerToken = JSON.parse(
                        data.data.data
                    ).facebookToken;
                    if (providerToken !== "NONE") {
                        loginProviderComplete(provider, providerToken);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // NKAPI.nkNoti(JSON.stringify(error));
                    loginProviderComplete();
                });
        }
    }
    function loginProviderComplete(provider, providerToken) {
        // Attempt login
        if (providerToken) {
            NKAPI.request(`/user/login/${provider}`, {
                accessToken: providerToken
            })
                .then((data) => {
                    // Stop listening
                    setIsProviderInflight("");
                    clearInterval(providerListener);

                    // User found, login
                    let userData = JSON.parse(data.data.data);

                    // Update user state
                    globalDispatch({
                        type: "user/login",
                        payload: {
                            user: userData.user,
                            session: userData.session
                        }
                    });

                    if (NKAPI.debug || NKAPI.gotoProfile()) {
                        navigate("/profile");
                        NKAPI.sendToSDK("//onlylogin", data.data);
                    } else {
                        NKAPI.sendToSDK("//onlylogin", data.data);
                    }
                })
                .catch((error) => {
                    // Stop listening
                    setIsProviderInflight("");
                    clearInterval(providerListener);
                    // console.log(error);

                    // Provider not linked with any account
                    if (
                        error?.response?.data?.error?.type ===
                        "ERR_USER_CANNOT_FIND_LINK"
                    ) {
                        // Show error message
                        globalDispatch({
                            type: "modal/error",
                            payload: {
                                isShown: true,
                                errorMsg: "error.provider_notlinked"
                            }
                        });

                        // Take user to sign up screen
                        // globalDispatch({
                        //     type: "onboarding/update",
                        //     payload: {
                        //         onboarding: true, // false = login - true = sign up
                        //     },
                        // });
                    }
                });
        } else {
            // Stop listening
            setIsProviderInflight("");
            clearInterval(providerListener);
        }
    }

    return (
        <>
            {props?.providersAvailable?.indexOf("apple") > -1 &&
                NKAPI.platform === "ios" && (
                    <Button
                        id="apple"
                        text={t("onboarding.apple_signin")}
                        className="bg-apple focus:ring-apple"
                        icon={faApple}
                        onClick={loginProvider}
                        disabled={isProviderInflight}
                        spinner={isProviderInflight === "apple"}
                    />
                )}
            {props?.providersAvailable?.indexOf("twitch") > -1 &&
                NKAPI.platform === "steam" && (
                    <Button
                        id="twitch"
                        text={t("onboarding.login_with", {
                            provider: "Twitch"
                        })}
                        className="bg-twitch focus:ring-twitch"
                        icon={faTwitch}
                        onClick={loginProvider}
                        disabled={isProviderInflight}
                        spinner={isProviderInflight === "twitch"}
                    />
                )}
            {props?.providersAvailable?.indexOf("googleplay") > -1 &&
                NKAPI.platform === "android" && (
                    <Button
                        id="googleplay"
                        text={t("onboarding.login_with", {
                            provider: "Google Play"
                        })}
                        className="bg-googleplay focus:ring-googleplay"
                        icon={faGooglePlay}
                        onClick={loginProvider}
                        disabled={isProviderInflight}
                        spinner={isProviderInflight === "googleplay"}
                    />
                )}
            {props?.providersAvailable?.indexOf("steam") > -1 &&
                NKAPI.platform === "steam" && (
                    <Button
                        id="steam"
                        text={t("onboarding.login_with", {
                            provider: "Steam"
                        })}
                        className="bg-steam focus:ring-steam hover:!bg-[#283144]"
                        icon={faSteam}
                        onClick={loginProvider}
                        disabled={isProviderInflight}
                        spinner={isProviderInflight === "steam"}
                    />
                )}
            {props?.providersAvailable?.indexOf("gamecenter") > -1 &&
                NKAPI.platform === "ios" && (
                    <Button
                        id="gamecenter"
                        text={t("onboarding.login_with", {
                            provider: "Game Center"
                        })}
                        className="bg-gamecenter focus:ring-gamecenter"
                        image={GameCenter}
                        onClick={loginProvider}
                        disabled={isProviderInflight}
                        spinner={isProviderInflight === "gamecenter"}
                    />
                )}
            {props?.providersAvailable?.indexOf("gamecircle") > -1 &&
                NKAPI.platform === "android" && (
                    <Button
                        id="gamecircle"
                        text={t("onboarding.login_with", {
                            provider: "GameCircle"
                        })}
                        className="bg-amazon focus:ring-amazon"
                        image={GameCenter}
                        onClick={loginProvider}
                        disabled={isProviderInflight}
                        spinner={isProviderInflight === "gamecenter"}
                    />
                )}
        </>
    );
}
